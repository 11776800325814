<template>
    <div class="xrow">
        <h2> Brindes Selecionados:</h2>
        <ListaBrindes
          itemKey="id"
          :items="produtos"
          :campanha="item"
          :itemsSelected="item.brindes"
          :select-callback="addBrinde"
          :deselect-callback="removeBrinde"
        />
    </div>
</template>

<script>
import ListaBrindes from './ListaBrindes';
import _remove from 'lodash/remove';

export default {
  name: 'Brinde',
  props: ['item', 'produtos'],
  components: {
    ListaBrindes,
  },
  methods: {
    addBrinde(item) {
      this.item.brindes.push(item);
    },
    removeBrinde(id) {
      const brindes = [...this.item.brindes];
      _remove(brindes, (n) => +n.id === +id);
      this.item.brindes = brindes;
    },
  },
};
</script>

<style scoped>
</style>
