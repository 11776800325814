<template>
    <div class="xrow">
        <div class="table-container">
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Produto</th>
                        <th>Quantidade</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="!itemsSelected.length">
                        <td colspan="4" style="text-align: center;">Adicionar Brindes</td>
                    </tr>
                    <tr v-for="(i, index) in itemsSelected">
                        <td>{{ i.idExibicao || i.id }}</td>
                        <td>{{ i.desc }}</td>
                        <td>
                            <input 
                              class="form-control" 
                              v-model="itemsSelected[index].qtde" 
                              type="number" 
                              style="width: 150px;"
                              :disabled="campanha.erpId && campanha.id"
                            />
                        </td>
                        <td>
                            <button
                              class="button button-error"
                              title="Remover"
                              v-on:click="()=>deselectItem(i, index)"
                              :disabled="campanha.erpId && campanha.id"
                            >
                              <strong>&times;</strong>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <h2>ADICIONAR BRINDE:</h2>
        <div class="search-select">
          <input 
            class="form-control" 
            placeholder="Pesquisar por ID, descrição ou referência" 
            type="text" 
            v-model="searchBrinde"
            @input="debounceBrindes"
          />
          <ul class="list">
              <li class="list-item" v-for="item in brindesDisponiveis" v-on:click="() => selectItem(item)" :class="{ 'item-disabled': item.disabled }">
                  <div style="display: flex; align-items: center; justify-content: space-between; margin-right: 50px;">
                    <span>
                        {{ item.idExibicao || item.id }} - {{ item.descComp | truncate(80) }}
                    </span>
                  </div>
                  
                  <button 
                    class="button button-primary add-item" 
                    title="Adicionar"
                    :disabled="campanha.erpId && campanha.id || item.disabled && item.listType == 'brindes'"
                  >
                      &nbsp; + &nbsp;
                  </button>
              </li>
          </ul>
        </div>
    </div>
</template>

<style scoped>
.table-container {
  max-height: 230px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.table tbody > tr:nth-child(even) td {
  background-color: #fff;
}

.table tbody > tr:nth-child(odd) td {
  background-color: #f1f1f1;
}

.table tbody > tr > td {
  padding: 6px;
}

.list {
  padding: 10px 0;
  height: 200px;
  overflow-y: auto;
}

.list-item {
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  position: relative;
}

.list-item:hover {
  background-color: #ddd !important;
}

.list-item:nth-child(odd) {
  background-color: #f1f1f1;
}

.list-item:nth-child(even) {
  font-size: 14px;
}

.add-item {
  position: absolute;
  right: 8px;
  top: 6px;
  padding: 1px 5px;
}

.input-desconto {
  width: 100px;
  border: 1px solid #999999;
  border-radius: 4px;
  text-align: right;
}

.list-item.item-disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>

<script>
import _filter from 'lodash/filter';
import _some from 'lodash/some';
import _map from 'lodash/map';
import _debounce from 'lodash/debounce';
import { get } from "@/helpers/apiRequest";

export default {
  name: 'ListaBrindes',
  props: [
    'campanha',
    'items',
    'itemsSelected',
    'selectCallback',
    'deselectCallback',
    'itemKey',
  ],
  data() {
    return {
      debounceBrindes: _debounce(this.searchItems, 500),
      searchBrinde: '',
      selectedItems: [],
    };
  },
  computed: {
    brindesDisponiveis() {
      this.items.forEach(brinde => {
        brinde.disabled = this.itemsSelected.some(selectedItem => selectedItem.id === brinde.id);
        brinde.listType = 'brindes'
      });

      return this.items
    },
  },
  methods: {
    debounceBrindes: function (term) {
      this.searchItems(term);
    },
    searchItems(term, take = 36) {
      const termo = term.target._value;

      get(`/admin/campanhas-produtos-paginados?termo=${termo}&take=${take}`)
        .then((json) => {
          this.items = json.itens.map(item => ({
          ...item,
          listType: 'brindes',
        }));

        this.items.forEach(item => {
          const isSelected = this.selectedItems.some(selected => selected.id === item.id);
          item.disabled = isSelected && item.listType === 'brindes';
        });
        })
        .catch((err) => {});
    },
    selectItem(item) {
      const newItem = {
        id: item.id,
        idExibicao: item.idExibicao,
        desc: item.descComp,
        qtde: item.qtde || 1,
      };

      const alreadySelected = this.selectedItems.some(selected => selected.id === item.id);

      if (!alreadySelected) {
        this.selectedItems.push(item);
        this.$set(item, 'disabled', true);
        this.selectCallback(newItem);
      }
    },
    deselectItem(item, index) {
      this.selectedItems.splice(index, 1);

      this.$set(item, 'disabled', false);
      this.deselectCallback(item[this.itemKey]);
    },
    reloadItems() {
      this.searchItems('');
    },
  },
  created() {
    this.reloadItems();
  },
};
</script>
