<template>
  <div class="xrow">
    <div class="col-1-2">
      <div class="form-group">
        <label for="">Descrição:</label>
        <input 
          class="form-control" 
          maxlength="250" 
          v-model="item.descricao"
          :disabled="item.erpId && item.id" 
        />
        <div class="message">{{ validacao.firstError("item.descricao") }}</div>
      </div>
    </div>
    <div class="col-1-4">
      <div class="form-group">
        <label for="">Data de início:</label>
        <datetime
          inputId="dtIni"
          input-class="form-control"
          type="date"
          v-model="item.dataInicio"
          :max-datetime="item.dataFim"
          :phrases="{ ok: 'Confirmar', cancel: 'Fechar' }"
          value-zone="local"
          format="d/M/y"
          :disabled="item.erpId && item.id" 
        />
        <div class="message">{{ validacao.firstError("item.dtInicial") }}</div>
      </div>
    </div>
    <div class="col-1-4">
      <div class="form-group">
        <label for="">Data de término:</label>
        <datetime
          :disabled="item.dataFim === null || (item.erpId && item.id)"
          inputId="dtFim"
          input-class="form-control"
          type="date"
          :min-datetime="item.dataInicio"
          v-model="item.dataFim"
          :phrases="{ ok: 'Confirmar', cancel: 'Fechar' }"
          value-zone="local"
          format="d/M/y"
        />
        <div class="message">{{ validacao.firstError("item.dtFinal") }}</div>
      </div>
    </div>
    <div class="col-1-1">
      <div class="form-group">
        <label>Descreva o funcionamento da campanha:</label>
        <textarea 
          rows="3"  
          class="form-control" 
          v-model="item.funcionamento" 
          :disabled="item.erpId && item.id" 
        />
        <div class="message">
          {{ validacao.firstError("item.funcionamento") }}
        </div>
      </div>
    </div>
    <div>
      <div class="form-group">
        <label>Aplicar nos sistemas:</label>
        <label class="checkbox">
          <input
            type="checkbox"
            v-model="item.aplicab2b"
            :value="item.aplicab2b"
            :disabled="item.erpId && item.id" 
          />
          &ensp; B2B
        </label>
        <label class="checkbox">
          <input
            type="checkbox"
            v-model="item.aplicacallcenter"
            :value="item.aplicacallcenter"
            :disabled="item.erpId && item.id" 
          />
          &ensp; Callcenter
        </label>
        <label class="checkbox">
          <input
            type="checkbox"
            v-model="item.aplicafv"
            :value="item.aplicafv"
            :disabled="item.erpId && item.id" 
          />
          &ensp; Força de vendas
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Informacao",
  props: ["item", "validacao"],
};
</script>
