<template>
  <div class="xrow">
    <div class="table-container">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>ID</th>
              <th>Produto</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!itemsSelected.length">
              <td colspan="3" style="text-align: center;">Adicionar Produtos</td>
            </tr>
            <tr v-for="(i, index) in itemsSelected">
              <td>{{ i.idExibicao || i.id }}</td>
              <td>{{ i.descricao || i.descComp}}</td>
              <td>
                <button class="button button-error" title="Remover" v-on:click="() => deselectItem(i, index)">
                  <strong>&nbsp;&times;&nbsp;</strong>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <h2>ADICIONAR PRODUTO:</h2>
    <div class="search-select">
      <input 
        class="form-control" 
        placeholder="Pesquisar por ID, descrição ou referência" 
        type="text"
        v-model="searchQuery" 
        @input="debouncedSearch" 
      />
      <ul class="list">
        <li class="list-item" v-for="item in this.items" :class="{ 'item-disabled': isItemSelected(item) }" v-on:click="() => selectItem(item)">
          <span>
            {{ item.idExibicao || item.id }} - {{ item.descComp | truncate(80) }}
          </span>
          <button class="button button-primary add-item" title="Adicionar" :disabled=" isItemSelected(item)">&nbsp;&plus;&nbsp;</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.table-container {
  max-height: 230px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.table tbody>tr:nth-child(even) td {
  background-color: #fff;
}

.table tbody>tr:nth-child(odd) td {
  background-color: #f1f1f1;
}

.table tbody>tr>td {
  padding: 6px;
}

.table td .button-error {
  padding: 1px 5px;
}

.list {
  padding: 10px 0;
  height: 200px;
  overflow-y: auto;
}

.list-item {
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  position: relative;
}

.list-item:hover {
  background-color: #ddd !important;
}

.list-item:nth-child(odd) {
  background-color: #f1f1f1;
}

.list-item:nth-child(even) {
  font-size: 14px;
}

.add-item {
  position: absolute;
  right: 8px;
  top: 6px;
  padding: 1px 5px;
}

.list-item.item-disabled {
  opacity: 0.5; 
  pointer-events: none;
}
</style>

<script>
import _debounce from 'lodash/debounce';

export default {
  name: 'multiselectable',
  props: [
    'items',
    'itemsSelected',
    'selectCallback',
    'deselectCallback',
    'itemKey',
    'loadProdutos',
  ],
  data() {
    return {
      debouncedSearch: _debounce(this.searchItems, 500),
      searchQuery: '',
      selectedItems: [],
    };
  },
  methods: {
    debouncedSearch: function (term) {
      this.searchItems(term);
    },
    searchItems(term, take = 36) {
      const termo = term.target._value ?? '';
      this.loadProdutos(termo, take)
    },
    selectItem(item) {
      this.$set(item, 'disabled', true);
      this.selectCallback(item);
    },
    deselectItem(item, index) {
      this.itemsSelected.splice(index, 1);
      this.$set(item, 'disabled', false);
      this.deselectCallback(item[this.itemKey]);
    },
    isItemSelected(item) {
      return this.itemsSelected.some(selectedItem => selectedItem.id === item.id);
    },
  },
  filters: {
    truncate: (text, stop, clamp) => {
      return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
    },
  },
};
</script>
