<template>
    <div>
        <Loading :isActive="loading"></Loading>
        <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab title="Campanhas Metas">
                <ObjetivosOperadores/>
            </CTab>
            <CTab title="Campanhas Comerciais">
                <CampanhasComerciais/>
            </CTab>
        </CTabs>
    </div>
</template>

<script>
import ObjetivosOperadores from './components/ObjetivosOperadores/ObjetivosOperadores';
import CampanhasComerciais from './components/CampanhasComerciais/CampanhasComerciais';

export default {
  name: 'campanhas',
  components: {
    ObjetivosOperadores,
    CampanhasComerciais,
  },
  data() {
    return {
      descPlural: '',
      descSingular: '',
      loading: true
    };
  },
  methods: {
    loadDescricaoModulo() {
      const { modulos } = JSON.parse(localStorage.getItem('app'));
      const { descPlural, descSingular } = modulos.find(
        (m) => m.nome === 'ADMIN_CAMPANHA'
      );
      this.descPlural = descPlural;
      this.descSingular = descSingular;
      this.loading = false;
    },
  },
  beforeMount() {
    this.loadDescricaoModulo();
  },
};
</script>
